import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Parallax from "components/Parallax/Parallax.js";
// sections for this page
import HeaderLinks from "components/Header/HeaderLinks.js";
import SectionPills from "./Sections/SectionPills.js";

import styles from "assets/jss/material-kit-react/views/components.js";

const useStyles = makeStyles(styles);

export default function Components(props) {
  const classes = useStyles();
  const { ...rest } = props;
  return (
    <div>
      <Header
        rightLinks={<HeaderLinks />}
        fixed
        color="white"
        {...rest}
      />
      <Parallax image={require("assets/img/bg4.jpg")}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem>
              <div className={classes.brand}>
                <h1 className={classes.title}>
                  MACHINERY INSTALLATION & RELOCATION • PRECISION ALIGNMENTS • HYDRAULIC CYLINDER REPAIRS & TESTING • 
                  CYLINDER HONING • INDUSTRIAL MAINTENANCE & REPAIRS • DESIGN & FABRICATION • GENERAL ENGINEERING
                </h1>
                <h3 className={classes.subtitle}>
                  "With the extensive experience of our staff and comprehensive equipment and facilities, Brisford Pty. Ltd. 
                  is capable of providing prompt and professional services to any industry."
                </h3>
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>

      <div className={classNames(classes.main, classes.mainRaised)}>
        <SectionPills />
      </div>
      <Footer />
    </div>
  );
}
